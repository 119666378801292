import React, { useState } from 'react';
import { FaDiscord } from 'react-icons/fa'
import { useNavigate } from 'react-router-dom';
import './Login.css'

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const navigate = useNavigate();

    const handleSubmit = () => {
        const cookies = document.cookie.split(';');
       const  token = cookies.find((cookie) =>
      cookie.trim().startsWith('token')
    );
        const splitToken = token?.split('=');
        
    if(splitToken?.[1] != null)
    {
       navigate(`/main?token=${splitToken?.[1]}`) 
    }
      else{
        window.location.href ='https://discord.com/api/oauth2/authorize?client_id=1035046032213540914&redirect_uri=https%3A%2F%2Fwww.malluroleplay.com%2Fapi%2Flogin&response_type=code&scope=identify%20guilds'
      } 
       

    };

    return (
        <div className="flex justify-center items-center h-screen gradient-background">
            <div className="flex flex-col justify-center items-center bg-indigo-700 bg-gradient-to-b rounded shadow-xl  p-8 text-center">
                <img className='w-1/2' src='https://cdn.discordapp.com/attachments/828922134466265121/1117453471499694130/logo-512X512.gif'/>
                <h2 className="text-2xl mb-4 p-10 text-gray-100">Login with Discord</h2>
                
                    <button
                        onClick={handleSubmit}
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        <FaDiscord className="mr-2" />
                        <span>Log In</span>
                    </button>
                
            </div>
        </div>
    );
};

export default LoginPage;
