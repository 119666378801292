import React from 'react';
import Admin from './Components/Admin/Admin';
import NavBar from './Components/NavBar/NavBar';
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";
import LoginPage from './Components/Login/Login';
import ErrorPage from './Components/Error/Error';



function App() {
  return (
    <div>
      <Router>
        
        <Routes>
          <Route path='/' element={<LoginPage/>}/>
          <Route path='/main' element={<Admin/>}/>
          <Route path='/error' element={<ErrorPage/>}/>
        </Routes>
      </Router>
      
      
    </div>
  );
}

export default App;
