import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { AiFillCheckCircle, AiOutlineFieldTime, AiFillCloseCircle, AiOutlineExport } from 'react-icons/ai'
import { ToastContainer, toast } from 'react-toastify';
import NavBar from '../NavBar/NavBar';
import { useLocation, useNavigate } from 'react-router-dom'
import Dashboard from '../Dashboard/Dashboard';

interface item {
   userid: string;
   avatar: string;
   username: string;
   date: string;
   handleby:string | null;
}
interface itemApp {
   firstname: string;
   secondname: string;
   email: string;
   dob: string;
   pending:number;
   steamlink: string;
   userid: string;
   avatar: string;
   username: string;
   date: string;
   maingarage: string;
   sandygarage: string;
   paletogarage: string;
   mainhospital: string;
   policestations: string;
   govoffice: string;
   prison: string;
   paletohospital: string;
   warningfire: string;
   shootingfrmcar: string;
   enterganghouse: string;
   jumpingfrmbuilding: string;
   pittingcar: string;
   quitgame: string;
   spoilrp: string;
   provokecop: string;
   entersafezone: string;
   policeSituation: string;
   metagaming: string;
   powergaming: string;
   streamsnipping: string;
   combatlogging: string;
   robberySituation: string;
   provokeplayer: string;
   hideafterrob: string;
   kidnapperson: string;
   talkingooc: string;
   inpersonrobbery: string;
   noneofabove: string;
   questionpowergaming: string;
   fightSituationYes: string;
   fightSituationNo: string;
   discriminator: string;
   rpexp: string;
   
}




let  Admin: React.FC=()=> {
   const [dropdown, setDropDown] = useState(false);
   const [data, setData] = useState<item[]>([]);
   const [dataApplication, setDataApplication] = useState<itemApp[]>([]);
   const [application, setApplication] = useState(false);
   const [applicationForm, setApplicationForm] = useState(false);
   const [pending, setPending] = useState(false);
   const [pendingdata, setPendingdata] = useState<item[]>([]);
   const [accepted,setAccepted] = useState(false)
   const [accepteddata,setAccepteddata] = useState<item[]>([]);
   const [rejected,setrejected]=useState(false);
   const [rejecteddata,setrejecteddata]= useState<item[]>([]);
   const [isToken,setIsToken]=useState<string | null>(null);
   const [isNavPro,setIsNavPro]= useState<string | null>(null);
   const [isUsername,setIsUsername]=useState<string | null>(null);
   const [isUserid,setIsUserid]=useState<string | null>(null);
   const [reason,setReason] = useState<string | null>(null);
   const [isDashboard,setIsDashboard]=useState(true);
   const [appCount,setAppCount]=useState<string | null>(null);
   const [acceptedCount,setAcceptedCount]=useState<string | null>(null);
   const [pendingCount,setPendingCount]=useState<string | null>(null);
   const [rejectedCount,setRejectedCount]=useState<string | null>(null);

   const show = () => {
      setDropDown(!dropdown);
   };

   const navigate = useNavigate();
   const location = useLocation();
   useEffect(() => {
      
      const searchParams = new URLSearchParams(location.search);
      const token = searchParams.get('token');
      setIsToken(token)

      const getAdmin = axios.get('https://discord.com/api/v10/users/@me', {
         headers: {
             Authorization: `Bearer ${token}`,
         }
     }).then((res)=>{
         setIsNavPro(res.data.avatar)
         setIsUsername(res.data.username)
         setIsUserid(res.data.id)
     })
      
      const data = {
         accesstoken:token
      }
      
      const admin = axios.post('https://www.malluroleplay.com/api/checkAdmin',data).then((response) => {
         console.log(response.data.adminResponse)
         if(response.data.adminResponse < 1)
         {
            navigate('/error');
         }
         else{
            document.cookie = `token=${token};`
         }
        fetchData();
         
      })
      

      

      
   }, [])

   const fetchData = ()=>{
      const applications = axios.post('https://www.malluroleplay.com/api/applications').then((response) => {
         if (response.status === 200) {
            setData(response.data)
            setAppCount(response.data.length)
         }
      })
      const pendingapplications = axios.post('https://www.malluroleplay.com/api/pendingapplications').then((response) => {
         if (response.status === 200) {
            setPendingdata(response.data)
            setPendingCount(response.data.length)
         }
      })
      const acceptedapplications = axios.post('https://www.malluroleplay.com/api/acceptedapplications').then((response) => {
         if (response.status === 200) {
            setAccepteddata(response.data)
            setAcceptedCount(response.data.length)
         }
      })
      const rejectedapplications = axios.post('https://www.malluroleplay.com/api/rejectedapplications').then((response) => {
         if (response.status === 200) {
            setrejecteddata(response.data)
            setRejectedCount(response.data.length)
         }
      })
   }

   const handleApplication = (userid: string) => {
      const data = {
         Userid: userid
         
      }
      const getApplication = axios.post('https://www.malluroleplay.com/api/getapplication', data, {
         headers: {
            'Content-Type': 'application/json'
         }
      }).then((response) => {
         setApplication(false)
         setPending(false)
         setAccepted(false)
         setApplicationForm(true);

         setDataApplication(response.data)


      })

      
   }
   const handleAccept = (userid:string,username:string,avatar:string,steamlink:string,discriminator:string) =>{
      const data = {
         Userid:userid,
         Username:username,
         Avatar:avatar,
         By:isUserid,
         ByUsername:isUsername,
         steamLink:steamlink,
         disc:discriminator
         
      }
      console.log(data.ByUsername);

      const sendAccept = axios.post('https://www.malluroleplay.com/api/acceptapplication',data,{
         headers:{
            'Content-Type':'application/json'
         }
      }).then((response)=>{
         if(response.status === 200)
         {
            setAccepted(false);
            setApplicationForm(false);
            fetchData();
         }
         

      })
   }

   const handlePending = (userid:string,username:string,avatar:string,steamlink:string) =>{
      const data = {
         Userid:userid,
         Username:username,
         Avatar:avatar,
         By:isUserid,
         ByUsername:isUsername,
         steamLink:steamlink
      }

      const sendPending = axios.post('https://www.malluroleplay.com/api/pendingapplication',data,{
         headers:{
            'Content-Type':'application/json'
         }
      }).then((response)=>{
         setAccepted(false);
         setApplicationForm(false);
         fetchData();
      })
   }
   const handleReject = (userid:string,username:string,avatar:string) =>{
      const data = {
         Userid:userid,
         Username:username,
         Avatar:avatar,
         By:isUserid,
         ByUsername:isUsername,
         RejectReason:reason
      }

      const sendReject = axios.post('https://www.malluroleplay.com/api/rejectapplication',data,{
         headers:{
            'Content-Type':'application/json'
         }
      }).then((response)=>{
         setAccepted(false);
         setApplicationForm(false);
         fetchData();
      })
   }
   const handleDashboard = ()=>{
                              setIsDashboard(true)
                              setApplication(false)
                              setApplicationForm(false)
                              setPending(false)
                              setAccepted(false)
                              setrejected(false)
   }
   

   return (

      <div>
          <NavBar avatar={isNavPro} userid={isUserid} username={isUsername} />
          
         <aside id="default-sidebar" className="fixed  left-0 z-40 w-64 h-screen transition-transform  -translate-x-full sm:translate-x-0 " aria-label="Sidebar">
            <div className="h-full px-3 py-4 overflow-y-auto  bg-gray-900">
               <ul className="space-y-2 font-medium">
                  <li>
                     <a href="#" className="flex items-center p-2  rounded-lg text-white  hover:bg-gray-700">
                        <svg aria-hidden="true" className="w-6 h-6  transition duration-75 text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path><path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path></svg>
                        <span className="ml-3" onClick={handleDashboard}>Dashboard</span>
                     </a>
                  </li>
                  <li>
                     <button type="button"
                        onClick={show}
                        className="flex items-center w-full p-2  transition duration-75 rounded-lg group  text-white hover:bg-gray-700" aria-controls="dropdown-example" data-collapse-toggle="dropdown-example">
                        <span className="flex-1 ml-3 text-left whitespace-nowrap">Whitelist Applications</span>
                        <svg className="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                     </button>
                     <ul id="dropdown-example" className={`${dropdown ? 'block' : 'hidden'} py-2 space-y-2`}>
                        <li>
                           <a href="#" onClick={() => {
                              setApplication(true)
                              setApplicationForm(false)
                              setPending(false)
                              setAccepted(false)
                              setrejected(false)
                              setIsDashboard(false)
                           }} className="flex items-center w-full p-2 text-gray-100 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">Applications</a>
                        </li>
                        <li>
                           <a href="#" onClick={() => {
                              setApplication(false)
                              setApplicationForm(false)
                              setPending(true)
                              setAccepted(false)
                              setrejected(false)
                              setIsDashboard(false)
                           }} className="flex items-center w-full p-2 text-gray-100 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">Pending</a>
                        </li>
                        <li>
                           <a href="#"
                           onClick={() => {
                              setApplication(false)
                              setApplicationForm(false)
                              setPending(false)
                              setAccepted(true)
                              setrejected(false)
                              setIsDashboard(false)
                           }}
                            className="flex items-center w-full p-2 text-gray-100 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">Accepted</a>
                        </li>
                        <li>
                           <a href="#" 
                            onClick={() => {
                              setApplication(false)
                              setApplicationForm(false)
                              setPending(false)
                              setAccepted(false)
                              setrejected(true)
                              setIsDashboard(false)
                           }}
                           className="flex items-center w-full p-2 text-gray-100 transition duration-75 rounded-lg pl-11 group   hover:bg-gray-700">Rejected  </a>
                        </li>
                     </ul>
                  </li>

               </ul>
            </div>
         </aside>

         <div className="p-4 sm:ml-64 bg-gray-800 min-h-screen">

            {isDashboard ? (
               <Dashboard applicationCount={appCount} pendingCount={pendingCount} acceptedCount={acceptedCount} rejectedCount={rejectedCount}/>
            ):(<></>)}               
            {application ? (
               <div className="p-4 rounded-lg border-gray-700 bg-gray-900 min-h-fit  ">
                  <h1 className='text-2xl text-gray-100 p-3 underline'>Whitelist Applications</h1>
                  {/* CARD */}


                  <div className='grid grid-cols-5 gap-2'>
                     {data.map((item, index) => (
                        <div key={index} className="p-5 rounded-lg shadow bg-gray-800 border-gray-700 cursor-pointer hover:-translate-y-1"
                           onClick={() => handleApplication(item.userid)}
                        >
                           <div className='flex'>
                              <img
                                 className="w-8 h-8 rounded-full"
                                 src={`https://cdn.discordapp.com/avatars/${item.userid}/${item.avatar}.jpg`}
                                 alt="user photo"
                              />
                              <p className='text-xl ml-2 text-gray-100'>{item.username}</p>

                           </div>
                           <p className='ml-2 text-gray-100'>Applied On :-{item.date}</p>
                           {/* <a href="#" className="inline-flex items-center text-gray-100 p-2 mt-2 rounded-lg text-xl bg-blue-800">
                           See our guideline
                        </a> */}
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <>
               </>
            )}


            {applicationForm ? (
               <div className="p-4 rounded-lg border-gray-700 bg-gray-900 min-h-fit  ">
                  {dataApplication.map((item, index) => (
                     <>
                        <div className='flex'>
                           <h1 key={index}
                              className='text-2xl text-gray-100'>
                              Whitelist Application of {item.username}
                           </h1>
                           <button className='bg-red-700 text-gray-100 p-2 rounded-xl ml-auto m-2 ' onClick={() => {
                              setApplicationForm(!applicationForm)
                              setApplication(!application)
                           }}><AiOutlineExport className='text-2xl' /></button>
                        </div>
                        <div className="mt-10 sm:mt-0 lg:p-10">
                           <div className="md:grid md:grid-cols-3 md:gap-6">
                              <div className="md:col-span-1">
                                 <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-100">Discord Details</h3>
                                    <p className="mt-1 text-sm text-gray-500">Details fetched from discord profile</p>
                                 </div>
                              </div>
                              <div className="mt-5 md:col-span-2 md:mt-0">
                                 <div className="overflow-hidden shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-6 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <div className="flex items-center mt-1">

                                                <img
                                                   className="w-10  h-10 rounded-full"
                                                   src={`https://cdn.discordapp.com/avatars/${item.userid}/${item.avatar}.jpg`}
                                                   alt="user photo" />

                                                <span className="ml-2 text-gray-100 text-xl">{item.username}#{item.discriminator}</span>
                                             </div>
                                          </div>


                                          <div className="col-span-6 sm:col-span-4">
                                             <div className="flex items-center mt-1">
                                                <label className="block text-sm font-medium text-gray-100">
                                                   Discord UserID :-
                                                </label>
                                                <span className="ml-2 text-gray-100">{item.userid}</span>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>


                        <div className="hidden sm:block" aria-hidden="true">
                           <div className="py-5">
                              <div className="border-t border-gray-200" />
                           </div>
                        </div>


                        <div className="mt-10 sm:mt-0 lg:p-10">
                           <div className="md:grid md:grid-cols-3 md:gap-6">
                              <div className="md:col-span-1">
                                 <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-100">Personal Information</h3>
                                    <p className="mt-1 text-sm text-gray-500">Provide Your Real Details</p>
                                 </div>
                              </div>
                              <div className="mt-5 md:col-span-2 md:mt-0">

                                 <div className="overflow-hidden shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-6 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                                First name
                                             </label>
                                             <input
                                                type="text"
                                                name="first-name"
                                                id="first-name"
                                                autoComplete="given-name"
                                                required
                                                defaultValue={item.firstname}
                                                className="mt-1 p-2 block w-full bg-gray-800 border text-gray-100 border-gray-400 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                             />
                                          </div>

                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-700 dark:text-gray-100">
                                                Last name
                                             </label>
                                             <input
                                                type="text"
                                                name="last-name"
                                                id="last-name"
                                                autoComplete="family-name"
                                                required
                                                defaultValue={item.secondname}
                                                className="mt-1 p-2 block w-full bg-gray-800 border text-gray-100 border-gray-400 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                             />
                                          </div>

                                          <div className="col-span-6 sm:col-span-4">
                                             <label className="block text-sm font-medium text-gray-100">
                                                Email address
                                             </label>
                                             <input
                                                type="text"
                                                name="email-address"
                                                id="email-address"
                                                autoComplete="email"
                                                required
                                                defaultValue={item.email}
                                                className="mt-1 p-2 block w-full bg-gray-800 border text-gray-100 border-gray-400 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                             />
                                          </div>





                                          <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                             <label className="block text-sm font-medium text-gray-100">
                                                Date of Birth
                                             </label>
                                             <input
                                                type="date"
                                                name="date-of-birth"
                                                id="date-of-birth"
                                                autoComplete="date"
                                                readOnly
                                                defaultValue={item.dob}
                                                className="mt-1 p-2 block w-full bg-gray-800 border text-gray-100 border-gray-400 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                             />
                                          </div>

                                          <div className="col-span-6  sm:col-span-4 lg:col-span-4">
                                             <label className="flex text-sm font-medium text-gray-100">
                                                Steam Link

                                             </label>

                                             <input
                                                type="text"
                                                name="steamlink"
                                                id="steamlink"
                                                autoComplete="steamlink"
                                                required
                                                readOnly
                                                defaultValue={item.steamlink}
                                                className="mt-1 p-2 block w-full bg-gray-800 border text-gray-100 border-gray-400 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                             />

                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>



                        <div className="hidden sm:block" aria-hidden="true">
                           <div className="py-5">
                              <div className="border-t border-gray-200" />
                           </div>
                        </div>

                        <div className="mt-10 sm:mt-0 lg:p-10">
                           <div className="md:grid md:grid-cols-3 md:gap-6">
                              <div className="md:col-span-1">
                                 <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-100">Roleplay QnA</h3>

                                 </div>
                              </div>
                              <div className="mt-5 md:col-span-2 md:mt-0">
                                 <div className="overflow-hidden shadow-xl sm:rounded-md">
                                    <div className="px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                                Roleplay Experiance
                                             </label>
                                             <select
                                                id="roleplay"
                                                name="roleplay"
                                                autoComplete="roleplay"
                                                required
                                                aria-readonly
                                                value={item.rpexp}
                                                className="mt-1 block w-full bg-gray-800 text-gray-100 rounded-md border border-gray-400  py-2 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                             >
                                                <option>No Experiance</option>
                                                <option>1 Month to 6 Months</option>
                                                <option>6 Months to 1 Year</option>
                                                <option>Above 1 Year</option>
                                             </select>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </div>
                        </div>


                        <div className="hidden sm:block" aria-hidden="true">
                           <div className="py-5">
                              <div className="border-t border-gray-200" />
                           </div>
                        </div>


                        <div className="mt-10 sm:mt-0 lg:p-10">
                           <div className="md:grid md:grid-cols-3 md:gap-6">
                              <div className="md:col-span-1">
                                 <div className="px-4 sm:px-0">
                                    <h3 className="text-lg font-medium leading-6 text-gray-100">Roleplay Rules</h3>

                                 </div>
                              </div>


                              <div className="mt-5 md:col-span-2 md:mt-0">
                                 <div className="overflow-hidden shadow-xl sm:rounded-md">
                                    <div className="px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                                1. From the list below, choose only safe zones:
                                             </label>
                                             <div className='mt-5'>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="mainGarage"
                                                      id="mainGarage"
                                                      readOnly
                                                      checked={item.maingarage === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium 0 mb-2">
                                                      Main Garage
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="sandyGarage"
                                                      id="sandyGarage"
                                                      readOnly
                                                      checked={item.sandygarage === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 dark:text-gray-100 text-sm font-medium text-gray-700 mb-2">
                                                      Sandy Garage
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="paletoGarage"
                                                      id="paletoGarage"
                                                      readOnly
                                                      checked={item.paletogarage === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Paleto Garage
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="mainHospital"
                                                      id="mainHospital"
                                                      readOnly
                                                      checked={item.mainhospital === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Main Hospital
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="policeStation"
                                                      id="policeStation"
                                                      readOnly
                                                      checked={item.policestations === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Police Stations
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="govOffice"
                                                      id="govOffice"
                                                      readOnly
                                                      checked={item.govoffice === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Government Office
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="prison"
                                                      id="prison"
                                                      readOnly
                                                      checked={item.prison === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium mb-2">
                                                      Prison
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="paletoHospital"
                                                      id="paletoHospital"
                                                      readOnly
                                                      checked={item.paletohospital === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Paleto Hospital
                                                   </label>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>



                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                             2. Prior initiating a shootout situation with Police, what all things you must ensure :
                                             </label>

                                             <input
                                                type="text"
                                                name="warningFire"
                                                id="warningFire"
                                                readOnly
                                                defaultValue={item.warningfire}
                                                className="mt-2 block w-full bg-gray-800 text-gray-100 border p-2 border-gray-400 rounded-md shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                             />
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium  text-gray-100">
                                                3. Which all listed below comes under fear RP:
                                             </label>
                                             <div className='mt-5'>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="shooting"
                                                      id="shooting"
                                                      readOnly
                                                      checked={item.shootingfrmcar === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Shooting from inside a car
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="jumping"
                                                      id="jumping"
                                                      readOnly
                                                      checked={item.jumpingfrmbuilding === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Jumping from a top of building
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="entering"
                                                      id="entering"
                                                      readOnly
                                                      checked={item.enterganghouse === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Entering a gang house
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="pitting"
                                                      id="pitting"
                                                      readOnly
                                                      checked={item.pittingcar === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Pitting another player car
                                                   </label>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800 ">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium  text-gray-100">
                                                4. Choose the appropriate meaning for Lore Breaking:
                                             </label>
                                             <div className='mt-5'>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="quit"
                                                      id="quit"
                                                      readOnly
                                                      checked={item.quitgame === 'on' ? true : false}
                                                      className=" rounded-md  border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Quit the game in middle of a situation
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="spoil"
                                                      id="spoil"
                                                      readOnly
                                                      checked={item.spoilrp === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Spoiling another person RP scenario
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="provoke"
                                                      id="provoke"
                                                      readOnly
                                                      checked={item.provokecop === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Provoking a Cop
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="enterSafe"
                                                      id="enterSafe"
                                                      readOnly
                                                      checked={item.entersafezone === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Entering a safe zone while on an active situation
                                                   </label>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium  text-gray-100">
                                             5. From the below scenario, which all server rules are violated : 
                                                    While an active situation with Police or Gang, you have entered Main Garage.
                                             </label>

                                             <textarea
                                                id="copBait"
                                                name="about"
                                                rows={3}
                                                readOnly
                                                value={item.policeSituation}
                                                className="mt-1 bg-gray-800 p-2 text-gray-100 border border-gray-500 block w-full rounded-md  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium  text-gray-100">
                                                6. Using information from OOC chat comes under:
                                             </label>
                                             <div className='mt-5'>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="meta"
                                                      id="meta"
                                                      readOnly
                                                      checked={item.metagaming === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Meta Gaming
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="power"
                                                      id="power"
                                                      readOnly
                                                      checked={item.powergaming === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium mb-2">
                                                      Power Gaming
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="stream"
                                                      id="stream"
                                                      readOnly
                                                      checked={item.streamsnipping === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium mb-2">
                                                      Stream Snipping
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="combat"
                                                      id="combat"
                                                      readOnly
                                                      checked={item.combatlogging === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium mb-2">
                                                      Combat Logging
                                                   </label>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>
                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                             7. You are caught by a police for robbery situation. Will you be able to take revenge ?
                                                    Explain in detail.
                                             </label>
                                             <textarea id="robberySituation"
                                                name="about"
                                                rows={3}
                                                readOnly
                                                defaultValue={item.robberySituation}
                                                className="mt-1 bg-gray-800 p-2  text-gray-100 border border-gray-500 block w-full rounded-md  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium  text-gray-100">
                                                8. What do you mean by power gaming, provide an example :
                                             </label>
                                             <textarea id="powerGaming"
                                                name="about"
                                                rows={3}
                                                readOnly
                                                defaultValue={item.questionpowergaming}
                                                className="mt-1 bg-gray-800 p-2  text-gray-100 border border-gray-500 block w-full rounded-md  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                                          </div>
                                       </div>
                                    </div>
                                 </div>

                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                                9. Choose the appropriate one that you can do in safe zone :
                                             </label>
                                             <div className='mt-5'>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="provokePlayer"
                                                      id="provokePlayer"
                                                      autoComplete="rules"
                                                      readOnly
                                                      checked={item.provokeplayer === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Provoking another player
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="hide"
                                                      id="hide"
                                                      autoComplete="rules"
                                                      readOnly
                                                      checked={item.hideafterrob === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Use this place to hide from cop after the robbery
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="kidnap"
                                                      id="kidnap"
                                                      autoComplete="rules"
                                                      readOnly
                                                      checked={item.kidnapperson === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Kidnap a person
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="talkOoc"
                                                      id="talkOoc"
                                                      autoComplete="rules"
                                                      readOnly
                                                      checked={item.talkingooc === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      Talking OOC
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="inpersonRobbery"
                                                      id="inpersonRobbery"
                                                      autoComplete="rules"
                                                      readOnly
                                                      checked={item.inpersonrobbery === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      In person robbery
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="checkbox"
                                                      name="noneOfAbove"
                                                      id="noneOfAbove"
                                                      autoComplete="rules"
                                                      readOnly
                                                      checked={item.noneofabove === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-gray-100 text-sm font-medium  mb-2">
                                                      None of Above
                                                   </label>
                                                </div>
                                             </div>
                                          </div>
                                       </div>
                                    </div>
                                 </div>


                                 <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                    <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                       <div className="grid grid-cols-3 gap-6">
                                          <div className="col-span-6 sm:col-span-3">
                                             <label className="block text-sm font-medium text-gray-100">
                                             10. During a fight situation, you are dead by any means. Will you be able to re-enter and continue the situation ?
                                             </label>
                                             <div className='mt-5'>
                                                <div className='flex'>
                                                   <input
                                                      type="radio"
                                                      name="firework"
                                                      id="fightSituationYes"
                                                      autoComplete="rules"

                                                      checked={item.fightSituationYes === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-sm font-medium text-gray-100 mb-2">
                                                      Yes
                                                   </label>
                                                </div>
                                                <div className='flex'>
                                                   <input
                                                      type="radio"
                                                      name="firework"
                                                      id="fightSituationNo"
                                                      autoComplete="rules"

                                                      checked={item.fightSituationNo === 'on' ? true : false}
                                                      className=" rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                                                   />
                                                   <label className="ml-3 text-sm font-medium text-gray-100 mb-2">
                                                      No
                                                   </label>
                                                </div>
                                             </div>
                                          </div>

                                       </div>

                                       <div className="overflow-hidden mt-3 shadow-xl sm:rounded-md">
                                          <div className=" px-4 py-5 sm:p-6 bg-gray-800">
                                             <div className="grid grid-cols-3 gap-6">
                                                <div className="col-span-6 sm:col-span-3">
                                                   <label className="block text-sm font-medium  text-gray-100">
                                                       Reason for rejecting :
                                                   </label>
                                                   <textarea id="rejectReason"
                                                      name="about"
                                                      rows={3}
                                                      
                                                      onChange={(e)=>{setReason(e.target.value)}}
                                                      className="mt-1 bg-gray-800 p-2  text-gray-100 border border-gray-500 block w-full rounded-md  shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"></textarea>
                                                </div>
                                             </div>
                                          </div>
                                       </div>

                                       <div className=" px-4 py-3 text-right sm:px-6 bg-gray-800 ">
                                          <button
                                             type="submit"
                                             onClick={()=>handleAccept(item.userid,item.username,item.avatar,item.steamlink,item.discriminator)}
                                             className={`${item.pending >= 0 && item.pending < 2 ?'block':'hidden'} inline-flex m-1 justify-center rounded-md border border-transparent bg-green-600 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-green-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                          >
                                             <AiFillCheckCircle className='text-xl mr-1' />  Accept
                                          </button>
                                          <button
                                             type="submit"
                                             onClick={()=>handlePending(item.userid,item.username,item.avatar,item.steamlink)}
                                             className={`${item.pending < 1 ? 'block':'hidden'} inline-flex m-1 justify-center rounded-md border border-transparent bg-yellow-500 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-yellow-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                          >
                                             <AiOutlineFieldTime className='text-xl mr-1' /> Pending
                                          </button>
                                          <button
                                             type="submit"
                                             onClick={()=>handleReject(item.userid,item.username,item.avatar)}
                                             className={`${item.pending === 0 ?'block':'hidden'} inline-flex m-1 justify-center rounded-md border border-transparent bg-red-800 py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-red-900 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2`}
                                          >
                                             <AiFillCloseCircle className='text-xl mr-1' />   Reject
                                          </button>
                                       </div>
                                    </div>
                                 </div>


                              </div>
                           </div>
                        </div>













                     </>


                  ))}

               </div >
            ) : (
               <></>
            )}



            {pending ? (
               <div className="p-4 rounded-lg border-gray-700 bg-gray-900 min-h-fit  ">
                  <h1 className='text-2xl text-gray-100 p-3 underline'>Pending Whitelist Applications</h1>
                  {/* CARD */}


                  <div className='grid grid-cols-5 gap-2'>
                     {pendingdata.map((item, index) => (
                        <div key={index} className="p-5 rounded-lg shadow bg-gray-800 border-gray-700 cursor-pointer hover:-translate-y-1"
                           onClick={() => handleApplication(item.userid)}
                        >
                           <div className='flex'>
                              <img
                                 className="w-8 h-8 rounded-full"
                                 src={`https://cdn.discordapp.com/avatars/${item.userid}/${item.avatar}.jpg`}
                                 alt="user photo"
                              />
                              <p className='text-xl ml-2 text-gray-100'>{item.username}</p>

                           </div>
                           <p className='ml-2 text-gray-100'>Last Handled By :-{item.handleby}</p>
                           <p className='ml-2 text-gray-100'>Applied On :-{item.date}</p>
                           {/* <a href="#" className="inline-flex items-center text-gray-100 p-2 mt-2 rounded-lg text-xl bg-blue-800">
                      See our guideline
                   </a> */}
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <></>
            )}

            {accepted ? (
               <div className="p-4 rounded-lg border-gray-700 bg-gray-900 min-h-fit  ">
                  <h1 className='text-2xl text-gray-100 p-3 underline'>Accepted Whitelist Applications</h1>
                  {/* CARD */}


                  <div className='grid grid-cols-5 gap-2'>
                     {accepteddata.map((item, index) => (
                        <div key={index} className="p-5 rounded-lg shadow bg-gray-800 border-gray-700 cursor-pointer hover:-translate-y-1"
                           onClick={() => handleApplication(item.userid)}
                        >
                           <div className='flex'>
                              <img
                                 className="w-8 h-8 rounded-full"
                                 src={`https://cdn.discordapp.com/avatars/${item.userid}/${item.avatar}.jpg`}
                                 alt="user photo"
                              />
                              <p className='text-xl ml-2 text-gray-100'>{item.username}</p>

                           </div>
                           <p className='ml-2 text-gray-100'>Last Handled By :-{item.handleby}</p>
                           <p className='ml-2 text-gray-100'>Applied On :-{item.date}</p>
                           {/* <a href="#" className="inline-flex items-center text-gray-100 p-2 mt-2 rounded-lg text-xl bg-blue-800">
                      See our guideline
                   </a> */}
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <></>
            )}

         {rejected ? (
               <div className="p-4 rounded-lg border-gray-700 bg-gray-900 min-h-fit  ">
                  <h1 className='text-2xl text-gray-100 p-3 underline'>Rejected Whitelist Applications</h1>
                  {/* CARD */}


                  <div className='grid grid-cols-5 gap-2'>
                     {rejecteddata.map((item, index) => (
                        <div key={index} className="p-5 rounded-lg shadow bg-gray-800 border-gray-700 cursor-pointer hover:-translate-y-1"
                           onClick={() => handleApplication(item.userid)}
                        >
                           <div className='flex'>
                              <img
                                 className="w-8 h-8 rounded-full"
                                 src={`https://cdn.discordapp.com/avatars/${item.userid}/${item.avatar}.jpg`}
                                 alt="user photo"
                              />
                              <p className='text-xl ml-2 text-gray-100'>{item.username}</p>

                           </div>
                           <p className='ml-2 text-gray-100'>Last Handled By :-{item.handleby}</p>
                           <p className='ml-2 text-gray-100'>Applied On :-{item.date}</p>
                           {/* <a href="#" className="inline-flex items-center text-gray-100 p-2 mt-2 rounded-lg text-xl bg-blue-800">
                      See our guideline
                   </a> */}
                        </div>
                     ))}
                  </div>
               </div>
            ) : (
               <></>
            )}







































         <ToastContainer/>

         </div>

      </div>

   )
}

export default Admin