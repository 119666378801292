import React from 'react';
import { BsFileEarmarkPersonFill } from 'react-icons/bs';

interface NavBarProps {
  applicationCount: string | null;
  pendingCount: string | null;
  acceptedCount: string | null;
  rejectedCount: string | null;
}

let Dashboard: React.FC<NavBarProps> = ({
  applicationCount,
  pendingCount,
  acceptedCount,
  rejectedCount,
}) => {
  return (
    <div className="flex flex-wrap gap-4">
      <div className="w-full sm:w-1/2 md:w-1/4">
        <div className="max-w-sm p-6 border rounded-lg shadow-lg bg-gray-600 border-gray-700">
          <BsFileEarmarkPersonFill />
          <a href="#">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white">
              Whitelist Applications
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-200">Count: {applicationCount}</p>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/4">
        <div className="max-w-sm p-6 border rounded-lg shadow-lg bg-gray-600 border-gray-700">
          <BsFileEarmarkPersonFill />
          <a href="#">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white">
              Whitelist Application Pending
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-200">Count: {pendingCount}</p>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/4">
        <div className="max-w-sm p-6 border rounded-lg shadow-lg bg-gray-600 border-gray-700">
          <BsFileEarmarkPersonFill />
          <a href="#">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white">
              Whitelist Application Accepted
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-200">Count: {acceptedCount}</p>
        </div>
      </div>

      <div className="w-full sm:w-1/2 md:w-1/4">
        <div className="max-w-sm p-6 border rounded-lg shadow-lg bg-gray-600 border-gray-700">
          <BsFileEarmarkPersonFill />
          <a href="#">
            <h5 className="mb-2 text-2xl font-semibold tracking-tight text-white">
              Whitelist Application Rejected
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-200">Count: {rejectedCount}</p>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
