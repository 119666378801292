import React, { useState } from 'react';
import { FaDiscord } from 'react-icons/fa'
import './Error.css'

const ErrorPage: React.FC = () => {




    

    return (
        <div className="flex justify-center items-center h-screen gradient-background">
            <div className="flex flex-col justify-center items-center bg-indigo-700 bg-gradient-to-b rounded shadow-xl  p-8 text-center">
                <img className='w-1/2' src='https://cdn.discordapp.com/attachments/828922134466265121/1117453471499694130/logo-512X512.gif'/>
                <h2 className="text-2xl mb-4 p-10 text-gray-100">Error !!!!</h2>
                
                    <button
                        
                        className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded flex items-center"
                    >
                        <FaDiscord className="mr-2" />
                        <span>You are not admin.</span>
                    </button>
                
            </div>
        </div>
    );
};

export default ErrorPage;
